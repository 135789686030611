import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from "../../components/MetaPages.jsx";
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
import { getPageAPI } from "../../data/tpagina.jsx";
import NavAreaPrivada from "../../components/NavAreaPrivada.jsx";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext.jsx";
import { getEncomendasAbertasPHC, getContaCorrentePendentePHC, getTotaisContaCorrentePHC } from "../../data/apiPHC.jsx";
import { formatarData } from "../../services/utils.jsx";
import LoadingPage from "../../components/LoadingPage.jsx";
import { getMultimediaByCategoryAndLanguage } from "../../data/multimedia.jsx";
import DashboardPromo from "../../components/DashboardPromo/DashboardPromo.jsx";

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import ReactGA from 'react-ga4';

import './dashboard.scss'

const Dashboard = () => {
    const { userData } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [encomendas, setEncomendas] = useState([])
    const [casasDecimais, setCasasDecimais] = useState(2)

    const [contaCorrente, setContaCorrente] = useState([])
    const [saldo, setSaldo] = useState(0)

    const [imagesPromo, setPromoImages] = useState("")

    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

    async function getImagesSlider(){
    if(!imagesPromo){
        const response = await getMultimediaByCategoryAndLanguage("promocoes",currentLanguage)
        setPromoImages(response)
    }
    }

    async function getContaCorrente(){
        const response = await getContaCorrentePendentePHC(userData.no)
        setContaCorrente(response)
    }

    async function getSaldo(){
        const response = await getTotaisContaCorrentePHC(userData.no)
        setSaldo(response[0].saldo)
    }
    
    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    async function getPage(){
        const response = await getPageAPI("dashboard", "dashboard", currentLanguage)
        setPage(response)
    }

    async function getEncomendas(){
        const response = await getEncomendasAbertasPHC(userData.no)
        setEncomendas(response)
    }

    useEffect(() =>{
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getEncomendas(),
                        getCasasDecimais(),
                        getContaCorrente(),
                        getSaldo(),
                        getImagesSlider()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])
    
    return (
        <>   
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="reservada">
                <div className="container-fluid">
                    <div className="row">
                        <NavAreaPrivada></NavAreaPrivada>
                        <main className="col-md-9 ms-md-auto col-lg-10 px-md-4 py-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                            {t(currentLanguage+'_area-privada')}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t(currentLanguage+'_dashboard')}
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="h2">{t(currentLanguage+'_dashboard')}</h1>
                            <p>
                                {t(currentLanguage+'_bem-vindo-area-privada')} <span className="notranslate"><b>{userData.name}</b></span>, {t(currentLanguage+'_podera-consultar-info-compras')}.
                            </p>
                                {/* <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
                                    <div className="card">
                                        <h5 className="card-header">{t(currentLanguage+'_compras')}</h5>
                                        <div className="card-body">
                                        <h5 className="card-title">43</h5>
                                        <p className="card-text">Feb 1 - Mar 1</p>
                                        <p className="card-text text-danger">-2.6% {t(currentLanguage+'_no-ultimo-mes')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-3">
                                    <div className="card">
                                        <h5 className="card-header">{t(currentLanguage+'_total')}</h5>
                                        <div className="card-body">
                                        <h5 className="card-title">64.000€</h5>
                                        <p className="card-text">Feb 1 - Mar 1</p>
                                        <p className="card-text text-success">+2.5% {t(currentLanguage+'_no-ultimo-mes')}</p>
                                        </div>
                                    </div>
                                </div> */}
                            
                            <div className="row">
                                {loading ? (
                                    <LoadingPage/>
                                ):(
                                    <>
                                        <div className="row my-4">
                                            
                                            <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-3 dashcard">
                                                <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/contacorrente`} className="text-decoration-none">
                                                    <div className="card">
                                                        <h5 className="card-header">
                                                            {t(currentLanguage+"_dashboard-cc-saldo")}
                                                        </h5>
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                &nbsp;
                                                            </h5>
                                                            <p className={`card-text text-center ${saldo >= 0 ? ""  : "text-danger"}`}>
                                                                {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(saldo)}
                                                            </p>
                                                            {/* <p className="card-text text-danger">-2.6% {t(currentLanguage+'_no-ultimo-mes')}</p> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-md-6 mb-4 mb-lg-0 col-lg-3 dashcard">
                                                <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/contacorrente/ccp`} className="text-decoration-none">
                                                    <div className="card">
                                                        <h5 className="card-header">
                                                            {t(currentLanguage+"_dashboard-cc-pendentes")}
                                                        </h5>
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                &nbsp;
                                                            </h5>
                                                            <p className="card-text text-center">
                                                                {/* <span>
                                                                    Saldo
                                                                </span>
                                                                &nbsp; */}
                                                                {contaCorrente.length}
                                                            </p>
                                                            {/* <p className="card-text text-danger">-2.6% {t(currentLanguage+'_no-ultimo-mes')}</p> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            {imagesPromo && imagesPromo.length > 0 ? (
                                            <div className="col-12 col-md-12 mb-4 col-lg-6 dashcard">
                                                <div className="card">
                                                    {/* <h5 className="card-header">
                                                        {t(currentLanguage+"_dashboard-empromocao")}
                                                    </h5> */}
                                                    <div className="card-body" id="promoslide">                                                  
                                                        <DashboardPromo/>
                                                    </div>
                                                </div>
                                            </div>
                                            ):(<div></div>)}                                           
                                        </div>
                                        <div className="col-12 col-xl-12 mb-4 mb-lg-0">
                                            <div className="card">
                                                <h5 className="card-header">{t(currentLanguage+'_ultimas-encomendas')}</h5>
                                                <div className="card-body">
                                                    {encomendas.length > 0 ? (
                                                        <>
                                                            <div className="tabresponsive-mobile">
                                                                <table className="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{t(currentLanguage+'_n-encomenda')}</th>
                                                                            <th scope="col">{t(currentLanguage+'_total')}</th>
                                                                            <th scope="col">{t(currentLanguage+'_data-encomenda')}</th>
                                                                            <th scope="col">{t(currentLanguage+'_estado-encomenda')}</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {encomendas.map((encomenda, index) => {
                                                                            return (
                                                                                <tr key={"linhaEncomendaDashboard"+index}>
                                                                                    <th scope="row">{encomenda.obrano}</th>
                                                                                    <td data-label={t(currentLanguage+'_total')}>
                                                                                        {encomenda.ebo12_iva+encomenda.ebo22_iva+encomenda.ebo32_iva+encomenda.ebo42_iva+encomenda.ebo52_iva+encomenda.ebo62_iva > 0 ? (
                                                                                            new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.ebo12_iva+encomenda.ebo22_iva+encomenda.ebo32_iva+encomenda.ebo42_iva+encomenda.ebo52_iva+encomenda.ebo62_iva+encomenda.etotaldeb)
                                                                                        ):(
                                                                                            new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(encomenda.etotaldeb * 1.23)
                                                                                        )}
                                                                                    </td>
                                                                                    <td data-label={t(currentLanguage+'_data-encomenda')}>
                                                                                        {formatarData(encomenda.dataobra)}
                                                                                    </td>
                                                                                    <td data-label={t(currentLanguage+'_estado-encomenda')}>
                                                                                        {encomenda.tabela1 === "Em Processamento" &&(
                                                                                            <span class="badge text-bg-info">
                                                                                                {t(currentLanguage+"_estado-em-preparacao")}
                                                                                            </span>
                                                                                        )}
                                                                                        {encomenda.tabela1 === "Nova Encomenda" &&(
                                                                                            <span class="badge text-bg-warning">
                                                                                                {t(currentLanguage+"_estado-nova-encomenda")}
                                                                                            </span>
                                                                                        )}
                                                                                        {encomenda.tabela1 === "Aguarda Pagamento" &&(
                                                                                            <span class="badge text-bg-warning">
                                                                                                {t(currentLanguage+"_estado-aguarda-pagamento")}
                                                                                            </span>
                                                                                        )}
                                                                                        {encomenda.tabela1 === "Enviado" &&(
                                                                                            <span class="badge text-bg-success">
                                                                                                {t(currentLanguage+"_estado-enviado")}
                                                                                            </span>
                                                                                        )}
                                                                                        {encomenda.tabela1 === "Cancelado" &&(
                                                                                            <span class="badge text-bg-danger">
                                                                                                {t(currentLanguage+"_estado-cancelado")}
                                                                                            </span>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/encomendas/${encomenda.obrano}`} className="btn btn-sm btn-primary">
                                                                                            {t(currentLanguage+'_consultar')}
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/encomendas`} className="btn btn-block btn-light">{t(currentLanguage+'_ver-todas')}</Link>
                                                        </>
                                                    ):(
                                                        <div class="alert alert-primary" role="alert">
                                                            <strong>
                                                                {t((currentLanguage+"_nao-existem-encomendas"))}
                                                            </strong>
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}                   
                            </div>                    
                        </main>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard