import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Carousel from "../components/Carousel";
import { getParameterByAliasAPI } from "../data/parameter";
import { getHomepagePages } from "../data/homepage";
import { getPageAPI } from "../data/tpagina";
import { Base64 } from 'js-base64';
import MetaPages from "../components/MetaPages";
import { getMultimediaByLanguage, getMultimediaByCategoryAndLanguage } from "../data/multimedia";
import { AuthContext } from "../contexts/authContext";
import { loginPHC } from "../data/apiPHC";
import { Link } from "react-router-dom";
import { PiGarageLight } from "react-icons/pi";
import { PiHandTapLight } from "react-icons/pi";
import { PiTire } from "react-icons/pi";
// import ModalPromo from "../components/ModalPromo";
import DashboardPromo from "../components/DashboardPromo/DashboardPromo";

// import { Modal } from "bootstrap";

import ReactGA from 'react-ga4';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './homepage.scss'

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

const Homepage = ({ reCaptchaRef }) => {
    const [loading, setLoading] = useState(false)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [pages, setPages] = useState("")
    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [ multimediaHome, setMultimediaHome ] = useState("")
    const [loadingLogin, setLoadingLogin] = useState(false)
    const [dataLogin, setDataLogin] = useState({"username" : "", "password": "", "remember": false})
    const {userData, handleAuthContext} = useContext(AuthContext)

    const [imagesPromo, setPromoImages] = useState("")
    const [inTimeoutPromo, setInTimeoutPromo] = useState(false)

    const handleCloseModal = () => {
        document.getElementById('modalPromo').click();
        setInTimeoutPromo(false)
    }

    async function getImagesSlider(){
        if(!imagesPromo){
            const response = await getMultimediaByCategoryAndLanguage("promocoes",currentLanguage)
            setPromoImages(response)
        }
    }

    async function activePromo() {
        if(!imagesPromo){
            const response = await getMultimediaByCategoryAndLanguage("promocoes",currentLanguage)
            setPromoImages(response)

            if(response.length > 0){
                setInTimeoutPromo(true)
            }else{
                setInTimeoutPromo(false)
            }
        }
    }

    async function handleLogin(e){
        e.preventDefault()
        if(!loadingLogin){
            setLoadingLogin(true)
            try {
                await loginPHC(dataLogin)
                handleAuthContext()
                activePromo()             
                setLoadingLogin(false)
            } catch (error) {
                setLoadingLogin(false)
            }
        }

    }

    async function getMultimedia(){
        const response = await getMultimediaByLanguage(currentLanguage)
        setMultimediaHome(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("home","homepage",currentLanguage)
        setPage(response)
    }

    async function getPages(){
        const response = await getHomepagePages(currentLanguage)
        setPages(response)
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getPages(),
                        getNomeCliente(),
                        getMultimedia()
                    ]);
                    const token = await reCaptchaRef.current.executeAsync();
                    console.log(token)
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    let imagem1, imagem2, imagem3, imagem4
    multimediaHome && multimediaHome.length > 0 && multimediaHome.map((imagem, i) => {
        if(imagem.alias === "homepage1"){
            return imagem1 = 'url('+BaseImgPath+imagem.path+')'
        }else if(imagem.alias === "homepage2"){
            return imagem2 = 'url('+BaseImgPath+imagem.path+')'
        }else if(imagem.alias === "homepage3"){
            return imagem3 = 'url('+BaseImgPath+imagem.path+')'
        }else if(imagem.alias === "homepage4"){
            return imagem4 = 'url('+BaseImgPath+imagem.path+')'
        }
    })

    // console.log('home ' + inTimeoutPromo)

    return (
        <>
        {/* <ModalPromo isenable={inTimeoutPromo}/> */}
        {inTimeoutPromo ? (
        <div className={`modal ${inTimeoutPromo} ? "show" : "hide" `} style={{ display: inTimeoutPromo ? 'block' : 'none' }} id="modalPromo" tabindex="-1" aria-hidden={inTimeoutPromo ? undefined : 'true'} data-bs-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* <h1 className="modal-title fs-5" id="exampleModalLabel">
                            {t(currentLanguage+"_dashboard-empromocao")}
                        </h1> */}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal()}></button>
                    </div>
                    <div className="modal-body">
                    <div id="promoslide">                                                  
                        <DashboardPromo/>
                    </div>
                    </div>
                    {/* <div className="modal-footer"> */}
                        {/* <button id='closeModalPromo' className='btn btn-light p-2' onClick={() => handleCloseModal()}>
                            {t(currentLanguage+"_fechar")}
                        </button> */}
                        {/* <Link className='btn btn-secondary p-2' to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/comprar`} onClick={() => handleCloseModal()}>
                            {t(currentLanguage+"_confirmar-compra")}
                        </Link> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
        ):(<div></div>)}
        {page && nomeCliente &&(
            <MetaPages newLang={language} pageTitle={nomeCliente +" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
        )}
        <section id="homeacesso">
            <div className="container-fluid">
            <div className="row justify-content-center logincontenthome" style={{backgroundImage: imagem1, backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}}>
                <div className="col-lg-8 col-12 d-none d-lg-block">
                    <div className="image-wrapper">
                        <img  className="img-fluid" src='data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==' alt="imagem"/>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 login-form" style={{backgroundImage: imagem2,  backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}}>
                    {userData ? (
                        <div className="row form-el form-with-styler d-flex align-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <h1 className="fr-el-section-title mb-4">
                                    <strong>{t(currentLanguage+'_acesso')}</strong>
                                </h1>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <p className="fr-el-text fr-el-fonts-style mb-4">
                                    {t(currentLanguage+'_bem-vindo-loja')} <strong>{userData.name}</strong>
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/loja`} className="btn btn-outline-secondary p-2 btquerocomprar fr-el-text fr-el-fonts-style mb-4 bg-danger">
                                    {t(currentLanguage+'_area-privada')}
                                </Link>
                                {/* <br/>
                                <button disabled={inTimeoutPromo} type="button" className="btn btn-success btn-block" data-bs-toggle="modal" data-bs-target="#modalPromo">
                                            {t(currentLanguage+'_finalizar')}
                                            <i className="bi bi-caret-right"></i>
                                        </button> */}
                            </div>
                        </div>
                    ): (
                        <form onSubmit={(e) => handleLogin(e)} className="form-el form-with-styler" data-verified="">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h1 className="fr-el-section-title mb-4">
                                        <strong>{t(currentLanguage+'_acesso')}</strong>
                                    </h1>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <p className="fr-el-text fr-el-fonts-style mb-4">
                                        {t(currentLanguage+'_area-privada')}
                                    </p>
                                </div>
                                <div data-for="name" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                    <input value={dataLogin.username} onChange={(e) => setDataLogin({ ...dataLogin, username : e.target.value })} type="text" name="name" placeholder={t(currentLanguage+'_nome-utilizador')} data-form-field="name" className="form-control" id="name-form3-m" required/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-for="password">
                                    <input value={dataLogin.password} onChange={(e) => setDataLogin({ ...dataLogin, password: e.target.value})} type="password" name="password" placeholder={t(currentLanguage+'_password')} data-form-field="password" className="form-control" id="password-form3-m" required/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                    <input type="checkbox" className="form-check-input" id="thislembrar" checked={dataLogin.remember} onChange={(e) => setDataLogin({...dataLogin, remember : e.target.checked})}/>
                                    <label className="form-check-label" for="thislembrar"><span className="fr-el-text ">{t(currentLanguage+'_lembrar-me')}</span></label>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mbr-section-btn">
                                    <button type="submit" className="btn btn-secondary btn-black">
                                        {loadingLogin ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">{t(currentLanguage+'_aguarde')}</span>
                                            </div> 
                                        ):(
                                            <span>{t(currentLanguage+'_aceder')}</span>
                                        )}
                                    </button>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 footerform">
                                    <p className="fr-el-text">
                                        {t(currentLanguage+'_nao-tem-acesso')}
                                        <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/registo`}>
                                            {t(currentLanguage+'_faca-o-seu-pedido-de-acesso')}
                                        </Link>
                                    </p>
                                    <p className="fr-el-text">
                                        {t(currentLanguage+'_ficou-sem-acesso')}
                                        <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/recuperar`}>
                                            {t(currentLanguage+'_recuperar')}
                                        </Link>
                                    </p>
                                </div>                         
                            </div>
                        </form>
                    )}
                </div>
            </div>
            </div>
        </section>        
        <section id="nossasmarcas">
            <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="container rounded">
                        <h1 className="text-center">{t(currentLanguage+'_marcas')}</h1>
                        <Carousel />
                    </div>
                </div>
            </div>
        </section>
        <section id="nossocliente">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="title col-12 col-md-9">
                        <h3 className="fr-section-title mb-4 display-2 text-center"><strong>{t(currentLanguage+'_homepage-text-1-1')}</strong><br/><strong>{t(currentLanguage+'_homepage-text-1-2')}</strong></h3>
                        <h4 className="fr-section-subtitle fr-fonts-style mb-4 display-7">
                            {pages && pages.length > 0 && pages.map((page) => {
                                if(page.pageSeoSlug === "text_1"){
                                    return <span key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></span>
                                }
                            })}
                        </h4>
                        {!userData &&(
                            <div className="align-center">
                                <Link className="btn btn-lg btn-secondary btred-all" to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/registo"}>
                                    {t(currentLanguage+'_criar-registo')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="handtyre-bk py-5 my-5" style={{backgroundImage: imagem3}}>
        </section>
        <section className="py-5" id="servicesquality">
            <div className="container px-5 my-5">
                <div className="row gx-5">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2 className="fw-bolder mb-0">
                            {t(currentLanguage+'_services-homepage-title')}
                        </h2>
                    </div>
                    <div className="col-lg-8">
                        <div className="row gx-5 row-cols-1 row-cols-md-2">
                            <div className="col mb-5 h-100">
                                <div className="feature bg-secondary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-truck"></i></div>
                                <h2 className="h5">{t(currentLanguage+'_servico-home-1')}</h2>
                                <p className="mb-0">
                                    {pages && pages.length > 0 && pages.map((page) => {
                                        if(page.pageSeoSlug === "homepage_servico_1"){
                                            return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                        }
                                    })}
                                </p>
                            </div>
                            <div className="col mb-5 h-100">
                                <div className="feature bg-secondary bg-gradient text-white rounded-3 mb-3"><PiHandTapLight /></div>
                                <h2 className="h5">{t(currentLanguage+'_servico-home-2')}</h2>
                                <p className="mb-0">
                                    {pages && pages.length > 0 && pages.map((page) => {
                                        if(page.pageSeoSlug === "homepage_servico_2"){
                                            return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                        }
                                    })}
                                </p>
                            </div>
                            <div className="col mb-5 mb-md-0 h-100">
                                <div className="feature bg-secondary bg-gradient text-white rounded-3 mb-3"><PiGarageLight /></div>
                                <h2 className="h5">{t(currentLanguage+'_servico-home-3')}</h2>
                                <p className="mb-0">
                                    {pages && pages.length > 0 && pages.map((page) => {
                                        if(page.pageSeoSlug === "homepage_servico_3"){
                                            return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                        }
                                    })}
                                </p>
                            </div>
                            <div className="col h-100">
                                <div className="feature bg-secondary bg-gradient text-white rounded-3 mb-3"><PiTire /></div>
                                <h2 className="h5">{t(currentLanguage+'_servico-home-4')}</h2>
                                <p className="mb-0">
                                    {pages && pages.length > 0 && pages.map((page) => {
                                        if(page.pageSeoSlug === "homepage_servico_4"){
                                            return <div key={page.pageSeoSlug} dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                        }
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-5 herocontact" style={{backgroundImage: imagem4,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}}>
            <div className="container px-5 my-5">
                <h2 className="fw-bolder mb-4 text-white">{t(currentLanguage+'_entre-em-contacto')}</h2>
                <Link className="btn btn-lg btn-secondary btred-all" to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/contatos"}>
                    {t(currentLanguage+'_contacte-nos')}
                </Link>
            </div>
        </section>
        </>
    )
}

export default Homepage