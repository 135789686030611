import React, { useEffect, useRef, useState, useContext, memo} from "react";
import { CartContext } from "../../contexts/cartContext";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { getMultimediaByCategoryAndLanguage } from "../../data/multimedia";
import { ProdutoDestaque } from "./modimagem";
import { TiWeatherSunny } from "react-icons/ti";
import { TiWeatherSnow } from "react-icons/ti";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { MdImageSearch } from "react-icons/md";
import MetaPages from "../../components/MetaPages";
import { getParameterByAliasAPI } from "../../data/parameter";
import { getPageAPI } from "../../data/tpagina";
import Alternativas from "./alternativas";
import { getProductsPHC } from "../../data/apiPHC";
import LoadingPage from "../../components/LoadingPage";
import { addCartItemAPI } from "../../data/cart";
import { Link } from "react-router-dom";
import FilterComponent from "../../components/FilterComponent";

import Spinner from "../../components/Spinner";

import { AuthContext } from "../../contexts/authContext";
import ReactGA from 'react-ga4';

import './btwob.scss'

const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

// const FilterComponent = ({ filterText, onFilter, onClear, t, currentLanguage, isScrolled }) => (
//     <>        
//         <div className="col-12 pesquisa-artigos">
//             {typeof(filterText) === "string" ? (
//                 <input className="form-control filtroartigos" id="search"
//                 type="text"
//                 placeholder={t(currentLanguage+'_pesquisa-placeholder-loja')}
//                 aria-label="Search Input"
//                 value={filterText}
//                 onChange={onFilter}/>
//             ) : (
//                 <input className="form-control filtroartigos" id="search"
//                 type="text"
//                 placeholder={t(currentLanguage+'_pesquisa-placeholder-loja')}
//                 aria-label="Search Input"
//                 value={""}
//                 onChange={onFilter}/>
//             )}
//             <button
//                 type="button"
//                 className="btn btn-secondary cleanfiltro"
//                 onClick={onClear}
//             >
//                 {t(currentLanguage+'_limpar-loja')}
//             </button>
//         </div>
//         <div className="clearfix"><br/></div>
//         <div className="col-12 pesquisa-artigos">
//             <ul>
//                 <li>
//                     <input type="checkbox" className="form-check-input" checked={filterText.runflat}
//                         onChange={(e) => onFilter(e,"runflat")} id="op_runflat" name="runflat"/>
//                     <label className="form-check-label" htmlFor="op_runflat"><span>&nbsp;{t(currentLanguage+'_run-flat-loja')}</span></label>
//                 </li>
//                 <li>
//                     <input type="checkbox" className="form-check-input" checked={filterText.oldot}
//                         onChange={(e) => onFilter(e, "oldot")} id="op_oldot"/>
//                     <label className="form-check-label" htmlFor="op_oldot"><span>&nbsp;{t(currentLanguage+'_old-dot-loja')}</span></label>
//                 </li>
//                 <li>
//                     <input type="checkbox" className="form-check-input" checked={filterText.ev}
//                         onChange={(e) => onFilter(e, "ev")} id="op_ev"/>
//                     <label className="form-check-label" htmlFor="op_ev"><span>&nbsp;{t(currentLanguage+'_ev-loja')}</span></label>
//                 </li>
//                 <li>
//                     <div className="form-floating epoca-chs">
//                         <select className="form-select" id="selectepoca" onChange={e => onFilter(e, e.target.value)} aria-label="Default select epoca">
//                             <option value="" selected>{t(currentLanguage+'_todas-epocas-loja')}</option>
//                             <option selected={filterText.epoca === "AllSeason"} value="AllSeason">{t(currentLanguage+'_all-season-loja')}</option>
//                             <option selected={filterText.epoca === "Verão"} value="Verão">{t(currentLanguage+'_verao-loja')}</option>
//                             <option selected={filterText.epoca === "Inverno"} value="Inverno">{t(currentLanguage+'_inverno-loja')}</option>        
//                         </select>
//                         <label htmlFor="selectepoca">{t(currentLanguage+'_tipo-pneu-loja')}</label>
//                     </div>
//                 </li>
//                 <li>
//                     <div className={`text-center buttonCartStore ${isScrolled ? 'staySit' : ''}`} style={{width:"100%"}}>
//                         <Link className="text-center btn btn-danger cleanfiltro" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/carrinho`}>
//                             {t(currentLanguage+'_ver-carrinho')}
//                         </Link>
//                     </div>
//                 </li>
//             </ul>
//         </div>
//     </>
// );

const Btwob = () => {
    const {userData} = useContext(AuthContext)
    const { updateCart, cartItems  } = useContext(CartContext);
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState({}); //carrinho qtt
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [currentRow, setCurrentRow] = useState(null);
    const [isExpanded, setExpanded] = useState(false);
    const [products, setProducts] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [dataProduto, setDataProduto] = useState("")
    const [loadingAddCart, setLoadingAddCart] = useState(false)
    const [loadingAddCartItem, setLoadingAddCartItem] = useState({})

    const [difColorIfInCart, setDifColorIfInCart] = useState(false)
    const [imagesBrands, setImagesBrands] = useState([])

    const [successAddCartItem, setSuccessAddCartItem] = useState({})
    const [noStockAddCartItem, setNoStockAddCartItem] = useState({})

    const [casasDecimais, setCasasDecimais] = useState(2)

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    async function getProducts(){
        try {
            const response = await getProductsPHC(userData.no)
            setProducts(response)
        } catch (error) {
            window.location.reload()
        }
        
    }

    async function getDifColorBtn(){
        const response = await getParameterByAliasAPI("b2bcartexist")
        setDifColorIfInCart(response.int)
    }

    async function getBrandImages(){
        const response = await getMultimediaByCategoryAndLanguage("imagensmarca", currentLanguage)
        setImagesBrands(response)
    }
    
    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("produtos", "produtos", currentLanguage)
        setPage(response)
    }

    const handleQty = (e, stock) => {
        e.preventDefault();
        setNumber({...number,[e.target.name]: e.target.value});
        // if (e.target.value <= stock && e.target.value > 0){
            
        // }
    };

    const handleSubmit = async (e,referencia,ststamp) => {
        e.preventDefault();
        if(!loadingAddCart){
            if(userData.no && userData.clstamp){
                try {
                    setLoadingAddCart(true)
                    setLoadingAddCartItem({ [referencia]: true})
                    const qtd = number[referencia] || 1
                    const data = { no : userData.no, clstamp : userData.clstamp, products: [{ref: referencia, ststamp: ststamp, quantidade: qtd}]}
                    await addCartItemAPI(data,t,currentLanguage)
                    setLoadingAddCart(false)
                    setLoadingAddCartItem({ [referencia]: false})
                    setNumber(prevNumber => ({ ...prevNumber, [referencia]: 1 }));
                    updateCart()
                    setSuccessAddCartItem((prevState) => ({ ...prevState, [referencia]: true }));
                    setTimeout(() => {
                        setSuccessAddCartItem((prevState) => ({ ...prevState, [referencia]: false }));
                    }, 2000);
                } catch (error) {
                    setLoadingAddCart(false)
                    setLoadingAddCartItem({ [referencia]: false})
                    if(error === "nostock"){
                        setNumber(prevNumber => ({ ...prevNumber, [referencia]: 1 }));
                        setNoStockAddCartItem((prevState) => ({ ...prevState, [referencia]: true }));
                        setTimeout(() => {
                            setNoStockAddCartItem((prevState) => ({ ...prevState, [referencia]: false }));
                        }, 2000);
                    }
                }
            }
        }
    };

    const showNestedData = (e,row,index) => {
        e.preventDefault();
        const thistabrow = document.getElementById('row-'+index);
        const everySubs = document.querySelectorAll('[id^="subs"]');
        if(isExpanded === null){
            setExpanded(true);
            setCurrentRow(row);
            thistabrow.style.backgroundColor="FF5200";
        }else if(isExpanded === false){
            setExpanded(true);
            setCurrentRow(row);
            thistabrow.style.backgroundColor="FF5200";
        }else{
            if(row == currentRow){
                setExpanded(false);
                setCurrentRow(row);
                thistabrow.style.backgroundColor="rgba(212, 18, 0, 0.9)";
            }else{
                for (var i = 0; i<everySubs.length; i++) {
                    const rowSubs = document.getElementById(everySubs[i].parentNode.parentNode.id);
                    rowSubs.style.backgroundColor="rgba(212, 18, 0, 0.9)";
                }

                setExpanded(true);
                setCurrentRow(row);
                thistabrow.style.backgroundColor="FF5200";
            }
        }
    
    };

    const columns = [
        {
            id: 'marca',
            name: t(currentLanguage+'_marca-loja'),
            //width: '10%',
            //selector: row => row.marca,
            sortable: true,
            grow: 0,
            //wrap: false,
            selector: row => row.marca,
            // sortFunction: row => row.marca,
            cell: (row, index) => {
                const ismarca = row.marca.trim();
                const imageExists = imagesBrands.find(brandImage => (brandImage.alias).toUpperCase() === (ismarca).toUpperCase())
                if(imageExists && ismarca){
                    return(
                        <img className="imagemarca" src={BaseImgPath+imageExists.path} alt={ismarca} />
                    )
                }else{
                    return(
                        <img className="imagemarca" src={BaseImgPath+"/images/zoomtyre.png"} alt="zoomtyre logo" />
                    )
                }
            },
            // hide: 'sm', 
        },{
            id: 'medida',
            name: t(currentLanguage+'_medida-loja'),
            grow: 1,
            selector: row => <span>{row.medidaex}</span>,
            left:true,
        },
        // {
        //     id: 'ic',
        //     name: t(currentLanguage+'_ic-loja'),
        //     width: '5%',
        //     grow: 0,
        //     selector: row => row.ic,
        //     center: true,
        //     // hide: 'md',
        // },
        {
            id: 'iv',
            name: t(currentLanguage+'_iv-loja'),
            width: '10%',
            grow: 1,
            selector: row => row.iv,
            center: true,
            // hide: 'md',
        },{
            id: 'modelo',
            name: t(currentLanguage+'_modelo-loja'),
            selector: row => row.modelo,
            // left:true,
            center: true,
            // hide: 'md',
        },{
            id: 'epoca',
            name: t(currentLanguage+'_epoca-loja'),
            // maxWidth: '5%',
            grow: 0,
            center: true,
            cell: (row, index) => {
                const isepoca = row.epoca.trim();
                return(
                    isepoca == 'Verão'
                    ? <span className="epoca"><TiWeatherSunny/></span>
                    :isepoca == 'Inverno'
                    ? <span className="epoca"><TiWeatherSnow/></span>
                    :isepoca == 'AllSeason'
                    ? <span className="epoca"><TiWeatherPartlySunny/></span>
                    : <span className="epoca"><TiWeatherSunny/></span>
                )
            },
            hide: 'md',
        },{
            id: 'stock',
            name: t(currentLanguage+'_stock-loja'),
            grow: 0,
            // width: '7%',
            sortable: true,
            selector: row => row.stock,
            center: true,
        },{
            id: 'preco',
            name: t(currentLanguage+'_preco-loja'),
            grow: 0,
            sortable: true,
            cell: row => new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(row.preco), 
            center: true,
            selector: row => row.preco,
        },{
            id: 'rotulagem',
            name: t(currentLanguage+'_rotulagem-loja'),
            // width: '15%',
            // grow: 1,
            // hide: 'md',
            cell: row => (
                <div className="rotulagem">
                    <ul>
                        <li>
                            <img className="imagerotulagem" src={BaseImgPath+"/images/efienergbk.png"} alt="eficiencia energetica" />
                            {row.eficconscomb}
                        </li>
                        <li>
                            <img className="imagerotulagem" src={BaseImgPath+"/images/adpavmolhabk.png"} alt="aderencia em pavimento molhado" />
                            {row.aderpisomolh}
                        </li>
                        <li>
                            <img className="imagerotulagem" src={BaseImgPath+"/images/nivelruido.png"} alt="nivel de ruido" />
                            {row.ruidoexterior}
                        </li>
                    </ul>
                </div>
            ),
            center:true,
            hide: 'md',
        },{
            id: 'foto',
            name: t(currentLanguage+'_etiq-foto-loja'),
            width: '7%',
            grow: 1,
            center:true,
            cell: (row,index) => { 
                // console.log(row)
                return (
                    <a type="button" data-bs-toggle="modal" data-bs-target="#modalTeste" className="fotog" onClick={() => {setDataProduto(row)}}>
                        <MdImageSearch/>
                    </a>
                )
            },
            //  hide: 'sm',
        },
        // {
        //     id: 'qtt',
        //     // name: t(currentLanguage+'_etiq-foto-loja'),
        //     // width: '7%',
        //     grow: 0,
        //     center:true,
        //     // cell: (row,index) => { 
        //     //     return (
        //     //         <a type="button" data-bs-toggle="modal" data-bs-target="#modalTeste" className="fotog" onClick={() => {setDataProduto(row)}}>
        //     //             <MdImageSearch/>
        //     //         </a>
        //     //     )
        //     // },
        //     // hide: 'sm',
        // },
        {
            id: 'comprar',
            name: t(currentLanguage+'_comprar-loja'),
            // width: '15%',
            grow: 1,
            right: true,
            cell: (row, index) => {
                const valor = row.stock;
                return(
                    <>
                        {valor <= 0 && row.alternativa >= 1 &&(
                            <form className={"form-inline ms-auto btalternativa"} id={`subs-${index}`} key={row.referencia}>
                                <a href="javascript:void(null);" onClick={(e)=>showNestedData(e,row,index)} className={"btn btn-secondary"}>
                                    {t(currentLanguage+'_alternativas-loja')}
                                </a>
                            </form>
                        )}
                        {valor <= 0 && row.alternativa == 0 &&(
                            <span>&nbsp;</span>
                        )}
                        {valor > 0 &&(
                            <form className={"form-inline justify-content-between align-items-between"} key={row.stamp} ref={formRef}>
                                <input className={"form-control"} type="number" name={row.referencia} value={number[row.referencia]} key={row.referencia} onChange={(e)=>handleQty(e,row.stock)} min={1} max={row.stock} defaultValue="1"/>
                                {!loadingAddCartItem[row.referencia] ? (
                                    <>
                                        {successAddCartItem[row.referencia] === true && (
                                        <a className={"btn btn-success"}>
                                            <i className={"bi bi-check-circle-fill p-2"}></i>
                                        </a>
                                        )}
                                        {noStockAddCartItem[row.referencia] === true && (
                                            <a className={"btn btn-warning"}>
                                                 <i className={"bi bi-box-seam p-2"}></i>
                                            </a>
                                        )}
                                        {!successAddCartItem[row.referencia] && !noStockAddCartItem[row.referencia] && (
                                            <a className={`btn ${difColorIfInCart && cartItems.find(cartItem => (cartItem.referencia) === (row.referencia)) ? "btn-success" : "btn-primary"}`} onClick={(e)=>handleSubmit(e,row.referencia, row.stamp)}>
                                                <i className={"bi bi-cart3 p-2"}></i>
                                            </a>
                                        )}
                                    </>
                                ):(
                                    <a className={"btn btn-primary "}><Spinner/></a>
                                )}
                            </form>
                        )}
                    </>
                )
            }    
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => row.noStock,
            style: row => ({
                backgroundColor: 'rgba(212, 18, 0, 0.9)',
                color: 'white',
                //display: row.alternativa == 0 ? 'none' : 'inherit',
                '&:hover': {
                    cursor: 'not-allowed',
                },
            }),
        }
    ];

    const tableCustomStyles = {
        headRow: {
          style: {
            color:'#fff',
            backgroundColor: 'rgb(99, 102, 105)'
          },
        },
        rows: {
          style: {
            color: 'NORMALCOLOR',
            backgroundColor: '#C7C8CC'
            //backgroundColor: '#EAD8C0'
            //backgroundColor: '#F79540'
          },
          stripedStyle: {
            color: 'NORMALCOLOR',
            backgroundColor: '#B4B4B8'
            //backgroundColor: '#D1BB9E'
            //backgroundColor: '#FC4F00'
          }
        }
      }

    const paginationComponentOptions = {
        rowsPerPageText: t(currentLanguage+"_artigos-por-pagina-tabela"),
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: t(currentLanguage+"_todos-tabela")
    };
	
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};
		return (
			<FilterComponent 
                onFilter={(e, type) => {
                    setExpanded(false)
                    if((e.target.value.length > 0 || e.target.checked) && e.target.type !== "checkbox" && e.target.type !== "select-one"){
                        setFilterText((e.target.value))
                    }else if(e.target.type === "select-one"){
                        setFilterText({epoca : type})
                    }else{
                        if(e.target.checked){
                            setFilterText({[type] : e.target.checked} || "");
                        }else{
                            setFilterText("")
                        }
                    }
                }}
                onClear={handleClear} 
                filterText={filterText}
                t={t}
                currentLanguage={currentLanguage}
                />
		);
	}, [filterText, resetPaginationToggle]);

    const ExpandableComponent = ({ data }) =>  <Alternativas alternativas={data.alternativas} isExpanded={isExpanded} casasDecimais={casasDecimais} difColorIfInCart={difColorIfInCart} imagesBrands={imagesBrands} />

    useEffect(() => {
        const getData = async () => {
            if (!loading) {
                setLoading(true);
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getProducts(),
                        getCasasDecimais(),
                        getDifColorBtn(),
                        getBrandImages()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        getData();
    }, [])

    useEffect(() => {
        setFilteredItems(products.filter(item => (item.nome && typeof(filterText) === "string" && item.nome.toLowerCase().includes(filterText.toLowerCase())) || (item.pesquisa && item.pesquisa.toString().includes(filterText)) || (item.marca && typeof(filterText) === "string" &&  item.marca.toLowerCase().includes(filterText.toLowerCase())) || (item.modelo && typeof(filterText) === "string" && item.modelo.toLowerCase().includes(filterText.toLowerCase())) || item.ev && filterText.ev || item.oldot && filterText.oldot || item.runflat && filterText.runflat || item.epoca === filterText.epoca));
    }, [products, filterText])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])
    
    return (
        <>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section>
                <div className="container dagrelhab2b">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mbr-section-title mbr-fonts-style align-center mb-2 display-2">
                                <strong>{t(currentLanguage+'_loja-b2b')}</strong></h3>
                            <h5 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 display-7">
                                {t(currentLanguage+'_bem-vindo-loja')} <span className="notranslate">{userData.name}</span></h5>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>
                <div className="text-center buttonCartStore" style={{width:"100%"}}>
                    <Link className="text-center btn btn-outline-secondary p-2 btquerocomprar" style={{width:"90%"}} to={VITE_PUBLIC_ROOT+`/${currentLanguage}/carrinho`}>
                        {t(currentLanguage+'_ver-carrinho')}
                    </Link>
                </div>
            </section> */}
            <section style={{paddingTop:'inherit'}}>
                <div className="container dagrelhab2b">
                    <div className="row">                
                        <div className="col-12 col-lg-12 tabelaartigos">
                            <ProdutoDestaque produto={dataProduto}/>
                            {products.length > 0 ? (
                                <>
                                    <DataTable
                                        title={t(currentLanguage+'_artigos-disponiveis-loja')}
                                        persistTableHead = {true}
                                        columns={columns}
                                        data={filteredItems}
                                        conditionalRowStyles={conditionalRowStyles}                    
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        paginationPerPage={products.length}
                                        highlightOnHover={true}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        expandableRows
                                        expandableRowExpanded={(row) => (isExpanded === true ? (row === currentRow) : (false))}
                                        expandableRowsComponent={ExpandableComponent}
                                        noDataComponent={<div style={{ padding: '24px' }}>{t(currentLanguage+'_nao-encontramos-artigos-loja')}</div>}
                                        striped
                                        customStyles={tableCustomStyles}
                                    />
                                </>
                            ):(
                                <LoadingPage/>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Btwob