import React, { useEffect, useState, useContext } from "react"
import { getMoradasPHC, getModosEntregaPHC, changeModoEntregaPHC } from "../../data/apiPHC";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { CartContext } from "../../contexts/cartContext";
import MetaPages from "../../components/MetaPages";
import { getParameterByAliasAPI } from "../../data/parameter";
import { getPageAPI } from "../../data/tpagina";
import { getPaisesPHC, finalizarEncomendaPHC, finalizarEncInterPHC } from "../../data/apiPHC";
import LoadingPage from "../../components/LoadingPage";
import slugify from "react-slugify";
import ModalErroFinalizarEncomenda from "../../components/ModalErroFinalizarCompra";
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
import { Notify } from "../../services/Notify.service";
import ReactGA from 'react-ga4';

import { Base64 } from "js-base64";

import './finalizar.scss'

const Finalizar = () => {
    const [loadingFinalizarEncomenda, setLoadingFinalizarEncomenda] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingModoEntrega, setLoadingModoEntrega] = useState(false)
    const { cartItems, deliveryMode, updateCart } = useContext(CartContext)
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const { userData } = useContext(AuthContext);
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const [precoTotal, setPrecoTotal] = useState(0)
    const [casasDecimais, setCasasDecimais] = useState(2)
    const [moradas, setMoradas] = useState([])
    const [formasPagamento, setFormasPagamento] = useState([])
    const [moradaEntrega, setMoradaEntrega] = useState("")
    const [modosEntrega, setModosEntrega] = useState([])
    
    const [modoPagamento, setModoPagamento] = useState("")
    const [observation, setObservation] = useState("")
    const [paises, setPaises] = useState([])

    //CASO ERRO NO FINALIZAR COMPRA
    const [disabled, setDisabled] = useState("")
    const [stockLess, setStockLess] = useState([])
    const [diferentPrice, setDiferentPrice] = useState([])
    //

    const [totalQtt, setTotalQtt] = useState(0)
    async function handleChangeModoEntrega(modoEntregaS){
        const userNo = userData.no
        const data = {no: userNo, modoEntrega: {ststamp: modoEntregaS.ststamp, design: modoEntregaS.design, ref: modoEntregaS.ref}}
        if(!loadingModoEntrega){
            setLoadingModoEntrega(true)
            try {
                await changeModoEntregaPHC(data)
            } catch (error) {
            }
            updateCart()
            setLoadingModoEntrega(false)
        }
    }

    async function getPaises(){
        const response = await getPaisesPHC()
        setPaises(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getFormasPagamento(){
        const response = await getParameterByAliasAPI("formaspagamento")
        const array = (response.varchar).split(",")
        setFormasPagamento(array)
    }

    async function getModosEntrega(){
        const response = await getModosEntregaPHC(userData.zona)
        setModosEntrega(response)
    }

    async function getPage(){
        const response = await getPageAPI("finalizar-compra","compra",currentLanguage)
        setPage(response)
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    const getMoradas = async () => {
        const response = await getMoradasPHC(userData.no)
        setMoradas(response)
    }

    const finalizarEncomenda = async () => {

        // {"clstamp":"FAT16012043646,7780000-1 ","no":9,"name":"PALAVRORBITA, SA","email":"fatimasimoes.palavrorbita@gmail.com","ncont":"510299997","remember":false,"username":"PALAVRORBITA","zona":"CENTRO","tabiva":0,"ecoisento":false,"pais":1,...}
        if(!loadingFinalizarEncomenda){
            setLoadingFinalizarEncomenda(true)
            let nomeModoEntrega = (deliveryMode && deliveryMode.nome) || ""

            // ACRESCENTAR tabiva E ecoisento
            try {
                // const response = await finalizarEncomendaPHC(
                //     currentLanguage,
                //     userData.name,
                //     userData.no,
                //     userData.email,
                //     observation,
                //     modoPagamento,
                //     nomeModoEntrega,
                //     moradaEntrega.morada, 
                //     moradaEntrega.localidade,
                //     moradaEntrega.cpostal,
                //     cartItems,
                //     moradaEntrega.nome,
                //     moradaEntrega.pais
                // )

                const response = await finalizarEncInterPHC(
                    currentLanguage,
                    userData.name,
                    userData.no,
                    userData.email,
                    observation,
                    modoPagamento,
                    nomeModoEntrega,
                    moradaEntrega.morada, 
                    moradaEntrega.localidade,
                    moradaEntrega.cpostal,
                    cartItems,
                    moradaEntrega.nome,
                    moradaEntrega.pais,
                    userData.tabiva,
                    userData.ecoisento
                )
                setLoadingFinalizarEncomenda(false)
                if(!response){
                    return console.log("Não foi possível finalizar encomenda")
                }else if(response.data.status === 200){

                    // ALTERAR VALOR TOTAL QUE VEM ERRADO
                    // recebo o nencomenda e o nbostamp depois de corrigir o endpoint da API do backoffice
                    // recebo metodopagamento depois de corrigir o endpoint da API do backoffice

                    // o tipo de pagamento que vem do array dos parametros (transferencia,debitodireto,numerario,outro)
                    // para tornar isto mais dinamico consoante o tipo de pagamento, também muda a pagina personalizada
                    // neste momento a pagina default é obrigado-compra e passaria a ser obrigado-compra(default), mas por cada tipo de pagamento poderiamos ter obrigado-compra-transferencia, obrigado-compra-debitodireto, etc...

                    window.location.replace(`${VITE_PUBLIC_ROOT}/${currentLanguage}/obrigado/encomenda/obrigado-compra/${response.data.nencomenda}/${Base64.encode(response.data.total)}`)
                }
            } catch (error) {
                setLoadingFinalizarEncomenda(false)
                if(error && error.response && error.response.data && error.response.data.status){
                    if(error.response.data.status === 500){
                        return console.log(error.response.data)
                    }else if(error.response.data.status === 400 && error.response.data.message === "products-error"){
                        setDisabled(error.response.data.disabled)
                        setStockLess(error.response.data.stockLess)
                        setDiferentPrice(error.response.data.diferentPrice)
                        document.getElementById('modalErro').click();
                    }else if(error.response.data.status === 400 && error.response.data.message === "address-not-found"){
                        Notify("error",t(currentLanguage+"_selecione-morada"))
                    }else if(error.response.data.status === 400 && error.response.data.message === "delivery-mode-not-found"){
                        Notify("error",t(currentLanguage+"_selecione-modo-entrega"))
                    }else if(error.response.data.status === 400 && error.response.data.message === "payment-method-not-found"){
                        Notify("error",t(currentLanguage+"_selecione-metodo-pagamento"))
                    }else if(error.response.data.status === 400 && error.response.data.message === "error-min-quantity"){
                        let txtNotify = t(currentLanguage+"_error-min-quantity")
                        txtNotify = txtNotify.replace(/%MINQTT%/g, error.response.data.minQtt);
                        Notify("error",txtNotify)
                    }else{
                        console.log("Não foi possível finalizar encomenda")
                    }
                }
            }
        }
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente(),
                        getCasasDecimais(),
                        getMoradas(),
                        getFormasPagamento(),
                        getModosEntrega(),
                        getPaises()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        // const total = cartItems.reduce((acc, artigo) => acc + (artigo.preco * artigo.qtt), 0);
        let total = 0
        cartItems.filter((artigo => {
            if(artigo.ecovalor == 1 && userData.ecoisento == false){
                return total += (artigo.preco * artigo.qtt) + (artigo.eecoval * artigo.qtt)
            }else{
                return total += artigo.preco * artigo.qtt
            }
        }))
        const totalQtt = cartItems.reduce((qtt, artigo) => Number(qtt) + (Number(artigo.qtt)), 0);
        setPrecoTotal(total+(deliveryMode ? (deliveryMode.preco * totalQtt) : 0))
        setTotalQtt(totalQtt)
    }, [cartItems, deliveryMode]);   

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    let quantidadeArtigos = 0

    return (
        <>
            <ModalErroFinalizarEncomenda disabled={disabled} stockLess={stockLess} diferentPrice={diferentPrice}/>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente +" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            {/* TITULO */}
            <section>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h2 className="mbr-section-title mbr-fonts-style align-center mb-2 display-2">
                                <strong>
                                    {t(currentLanguage+'_confirme-sua-compra')}
                                </strong>
                            </h2>
                            <p className="align-center">
                                {t(currentLanguage+'_confirme-dados-finalizar-compra')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONFIRMAÇÃO DE DADOS */}
            
            {loading ? (
                <section>
                    <LoadingPage/>
                </section>
            ):(
                <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="card">
                                        <form className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row DadosPessoais">
                                                        <h2>{t(currentLanguage+'_confirme-dados')}</h2>
                                                        <p>
                                                            <b>{t(currentLanguage+'_nome')}:</b>&nbsp;{userData.name}
                                                        </p>
                                                        <p>
                                                            <b>{t(currentLanguage+'_email')}:</b>&nbsp;{userData.email}
                                                        </p>
                                                        <p>
                                                            <b>{t(currentLanguage+'_nif')}:</b>&nbsp;{userData.ncont}
                                                        </p>
                                                        <p>
                                                            <b>{t(currentLanguage+'_zona')}:</b>&nbsp;{userData.zona}
                                                        </p><br/>
                                                        <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/perfil`} className="btn_alt btn-default">
                                                            <i className="bi bi-pencil-square"></i>&nbsp;{t(currentLanguage+"_alterar")}
                                                        </Link>
                                                    </div>
                                                    <hr/>
                                                    <div className="row DadosPessoais form_geral">
                                                        <h2>
                                                            {t(currentLanguage+'_confirme-moradas-entrega')}
                                                        </h2>
                                                        <b>
                                                            {t(currentLanguage+'_morada-entrega')}
                                                        </b>
                                                        <div className="form_geral_inputs col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                                            <select onChange={(e) => {
                                                                const selectedMorada = moradas.find(morada => morada.szadrsdesc === e.target.value) || "";
                                                                setMoradaEntrega({nome : e.target.value, morada : selectedMorada.morada || "", localidade: selectedMorada.local || "", cpostal: selectedMorada.codpost || "", pais: selectedMorada.pais || ""});
                                                            }} className="form-select" id="moradaDeEntrega" aria-label="Morada de Entrega">
                                                                <option value="" selected disabled hidden>{t(currentLanguage+'_escolha_morada_entrega')}</option>
                                                                {moradas.map((morada, index) => {
                                                                    return (
                                                                        <option key={index} value={morada.szadrsdesc}>{morada.szadrsdesc}</option>
                                                                    )
                                                                })}
                                                                <option value="outra-morada">{t(currentLanguage+"_outra-morada")}</option>
                                                            </select>
                                                        </div>
                                                        {moradaEntrega &&(
                                                                <form>
                                                                    <h4>
                                                                        {moradaEntrega.nome == "outra-morada" ? 
                                                                            t(currentLanguage+"_"+moradaEntrega.nome)
                                                                        :
                                                                            moradaEntrega.nome
                                                                        }
                                                                    </h4>
                                                                    <div class="row">
                                                                        <div className="input-wrapper col-lg mb-3">
                                                                            <label for="moradaInput" class="form-label">
                                                                                <b>{t(currentLanguage+"_morada")}</b> 
                                                                            </label>
                                                                            <input placeholder={t(currentLanguage+"_morada")} value={moradaEntrega.morada} onChange={(e) => setMoradaEntrega({...moradaEntrega, morada: e.target.value})} type="text" class="form-control" id="moradaInput" aria-describedby="moradaDescription" required readOnly={moradaEntrega.nome !== "outra-morada" ? true : false}/>
                                                                        </div>
                                                                        <div className="input-wrapper col-lg mb-3">
                                                                            <label for="localidade" class="form-label">
                                                                                <b>{t(currentLanguage+"_localidade")}</b>
                                                                            </label>
                                                                            <input placeholder={t(currentLanguage+"_localidade")} value={moradaEntrega.localidade} onChange={(e) => setMoradaEntrega({...moradaEntrega, localidade: e.target.value})} type="text" class="form-control" id="localidade" aria-describedby="localidade" required readOnly={moradaEntrega.nome !== "outra-morada" ? true : false}/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div className="input-wrapper col-md mb-3">
                                                                            <label for="codPostal" class="form-label">
                                                                                <b>{t(currentLanguage+"_codigo-postal")}</b>
                                                                            </label>
                                                                            <input placeholder={t(currentLanguage+"_codigo-postal")} value={moradaEntrega.cpostal} onChange={(e) => setMoradaEntrega({...moradaEntrega, cpostal: e.target.value})} type="text" class="form-control" id="codPostal" aria-describedby="zipcode" required readOnly={moradaEntrega.nome !== "outra-morada" ? true : false}/>
                                                                        </div>
                                                                        <div className="input-wrapper col-md mb-3">
                                                                            <label for="country" class="form-label">
                                                                                <b>{t(currentLanguage+"_pais")}</b>
                                                                            </label>
                                                                            {moradaEntrega.nome !== "outra-morada" ? (
                                                                                <input placeholder={t(currentLanguage+"_pais")} value={moradaEntrega.pais} onChange={(e) => setMoradaEntrega({...moradaEntrega, pais: e.target.value})} type="text" class="form-control" id="country" aria-describedby="country" required readOnly/>
                                                                            ):(
                                                                                <select name="country" id="country" className="form-control" value={moradaEntrega.pais} onChange={(e) => setMoradaEntrega({...moradaEntrega, pais: e.target.value})} placeholder={t(currentLanguage+"_pais")} required aria-describedby="country">
                                                                                    {paises.map((pais) => {
                                                                                        return(
                                                                                            <option value={pais.nome}>{pais.nome}</option>
                                                                                        )
                                                                                    })}
                                                                                    <option value="" disabled hidden>{t(currentLanguage+"_pais")}</option>
                                                                                </select>    
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            )}
                                                    </div>
                                                    <hr/>
                                                    <div className="row Outros dados" id="deliveryModes">
                                                        <h2>
                                                            {t(currentLanguage+'_modo-entrega')}
                                                        </h2>

                                                        {loadingModoEntrega ? (
                                                            <LoadingPage/>
                                                        ):(
                                                            modosEntrega.map((modoEntrega, index) => {
                                                                return (
                                                                    <div key={"modoEntregaDiv"+index} className="form_geral_inputs deliveryModes" >
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id={"modoEntrega"+index} required="required" value={modoEntrega.ststamp} checked={(deliveryMode && deliveryMode.ref) === modoEntrega.ref} onChange={(e) => {
                                                                                const selectedEntrega = modosEntrega.find(entrega => entrega.ststamp === e.target.value) || "";
                                                                                // setSelectedModoEntrega({ ststamp: selectedEntrega.ststamp, design: selectedEntrega.design, epv5: selectedEntrega.epv5, ref: selectedEntrega.ref});
                                                                                handleChangeModoEntrega(selectedEntrega);
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" for={"modoEntrega"+index}>
                                                                            &nbsp;{t(currentLanguage+"_"+slugify(modoEntrega.design)) + " ("+new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(modoEntrega.preco)+")"}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        )}
                                                        
                                                    </div>
                                                    <hr/>
                                                    <div className="row Outros pagamentos">
                                                        <h2>
                                                            {t(currentLanguage+'_forma-pagamento')}
                                                        </h2>
                                                        <div className="form_geral_inputs payModes d-flex flex-column">
                                                            {formasPagamento.map((pagamento, index) => {
                                                                return (
                                                                    <div key={"modoPagamento"+index}>
                                                                        <input className="form-check-input" type="radio" name="pagamento" id={"pagamentoInput"+pagamento} required="required" value={pagamento} onChange={(e) => setModoPagamento(e.target.value)}/>
                                                                        <label className="form-check-label" for={"pagamentoInput"+pagamento}>
                                                                            &nbsp;{t(currentLanguage+"_pagamento-"+pagamento)}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>                         
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container">
                        <form action="">
                            <div className="input-wrapper col-lg mb-3">
                                <label for="localidade" class="form-label">
                                    <b>{t(currentLanguage+"_observacoes")}</b>
                                </label>
                                <textarea className="form-control" rows={5} name="textarea-observations" id="textarea-observations" placeholder={t(currentLanguage+"_observacoes")} value={observation} onChange={(e) => setObservation(e.target.value)}></textarea>
                            </div>
                        </form> 
                    </div>
                    
                    {/* ZONA DO CARRINHO */}
                    <section className="pt-5 pb-5 carrinho">
                        <div className="container">
                            <div class="table-responsive" id="tabresponsive-cart">
                                {/* <table id="cart" className="table table-hover table-condensed "> */}
                                <table id="cart" class="dcf-table dcf-table-responsive dcf-table-bordered dcf-w-100%">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width:"50%"}}>{t(currentLanguage+'_produto')}</th>
                                            <th scope="col" style={{width:"10%"}}>{t(currentLanguage+'_preco')}</th>
                                            <th scope="col" style={{width:"8%"}}>{t(currentLanguage+'_quantidade')}</th>
                                            <th scope="col" className="text-center" style={{width:"10%"}}>{t(currentLanguage+'_ecovalor')}</th>
                                            <th scope="col" style={{width:"22%"}} className="text-center">{t(currentLanguage+'_subtotal')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((produto,i) =>{
                                            if (produto.qtt > 0) {
                                                quantidadeArtigos = quantidadeArtigos + parseInt(produto.qtt)
                                            }
                                        })}
                                        {cartItems.map(function(artigo) {
                                            return(
                                                <tr>
                                                    <th scope="row" data-th="Produto">
                                                        <div className="row">
                                                            <div className="col-sm-2 hidden-xs d-none"><img src="http://placehold.it/100x100" alt="..." className="img-responsive" /></div>
                                                            <div className="col-sm-10 dcf-txt-center-mobile">
                                                                <h5 className="nomargin">{artigo.nome}</h5>
                                                                {/* <p>{artigo.marca} - {artigo.ic} - {artigo.iv}</p> */}
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td scope="row" data-th="Preço" data-label={t(currentLanguage+'_preco')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.preco))}</td>
                                                    <td scope="row" data-th="Quantidade" data-label={t(currentLanguage+'_quantidade')} className="align-center">
                                                        <p>{artigo.qtt}</p>
                                                    </td>
                                                    <td scope="row" data-th="Ecovalor" data-label={t(currentLanguage+'_ecovalor')} className="align-center">
                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.ecovalor == 1 && userData.ecoisento == false ? artigo.qtt * artigo.eecoval : 0))}
                                                    </td>
                                                    <td scope="row" data-th="Subtotal" data-label={t(currentLanguage+'_subtotal')} className="text-center">{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.preco*artigo.qtt))}</td>
                                                </tr>
                                            )
                                        })}
                                        {deliveryMode && (
                                            <tr>
                                                <th scope="row" data-th="Produto">
                                                    <div className="row">
                                                        <div className="col-sm-2 hidden-xs d-none"></div>
                                                        <div className="col-sm-10">
                                                        <h5 className="nomargin dcf-txt-center-mobile">{t(currentLanguage+"_"+slugify(deliveryMode.nome))}</h5>
                                                        {/* <p>{artigo.marca} - {artigo.ic} - {artigo.iv}</p> */}
                                                        </div>
                                                    </div>
                                                </th>
                                                <td data-th="Preço" data-label={t(currentLanguage+'_preco')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco))}</td>
                                                <td data-th="Quantidade" className="align-center" data-label={t(currentLanguage+'_quantidade')}>{totalQtt}</td>
                                                <td data-th="Ecovalor" className="align-center" data-label={t(currentLanguage+'_ecovalor')}></td>
                                                <td data-th="Subtotal" className="text-center" data-label={t(currentLanguage+'_subtotal')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco * totalQtt))}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot className="grelhaFinalizarFooter">
                                        {/* <tr>
                                            <td>&nbsp;</td>
                                            <td className="text-center"><strong>Total Artigos</strong></td>
                                            <td className="text-center">
                                                {quantidadeArtigos}
                                            </td>
                                            <td colSpan="2"></td>
                                        </tr> */}
                                        <tr><td colSpan="5">&nbsp;</td></tr>
                                        <tr>
                                            <td colSpan="4" className="textright"><strong>{t(currentLanguage+'_total_artigos')}</strong></td>
                                            <td className="text-center">
                                                {quantidadeArtigos}
                                            </td>
                                        </tr>
                                        <tr className="visible-xs d-none">
                                            <td className="align-center">
                                                <strong>
                                                    {t(currentLanguage+'_total')}&nbsp;{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(precoTotal - (deliveryMode ? (deliveryMode.preco * totalQtt) : 0)))}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="textright">{t(currentLanguage+'_subtotal')}</td>
                                            <td className="text-center">
                                                {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(precoTotal - (deliveryMode ? (deliveryMode.preco * totalQtt) : 0)))}
                                            </td>
                                        </tr>
                                        {deliveryMode &&(
                                            <tr>
                                                <td colSpan="4" className="textright">{t(currentLanguage+'_portes-envio')}</td>
                                                <td className="text-center">
                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco * totalQtt)) || 0}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td colSpan="4" className="textright"><strong>{t(currentLanguage+'_total')}</strong></td>
                                            <td className="text-center">
                                                <strong>
                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(precoTotal))}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </section>
                    {/* BOTÕES FIM DE PÁGINA */}
                    <section>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12 d-flex flex-md-row flex-column justify-content-between align-items-between">
                                    <div>
                                        <Link className="btn btn-warning" to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/loja`}>
                                            <i className="fa fa-angle-left"></i> {t(currentLanguage+'_continuar-comprar')}
                                        </Link>
                                    </div>
                                    <div>
                                        <button className="btn btn-success btn-block" disabled={loadingFinalizarEncomenda} onClick={() => finalizarEncomenda()}>
                                            {t(currentLanguage+'_comprar')}
                                        </button>
                                        {/* <button className="btn btn-success btn-block" disabled={loadingFinalizarEncomenda} data-bs-toggle="modal" data-bs-target="#modalErroFinalizarEncomenda">
                                            {t(currentLanguage+'_comprar')}
                                        </button> */}
                                        {/* <button data-bs-toggle="modal" data-bs-target="#modalAlterarPassword" className="btn btn-md btn-secondary p-2 me-3">
                                                {t(currentLanguage+"_perfil-alterar-palavra-passe")}
                                            </button> */}
                                        {/* <Link className="btn btn-success btn-block" to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/obrigado/obrigado-compra`}> */}
                                            
                                        {/* </Link> */}
                                        <button type="button" id="modalErro" data-bs-toggle="modal" data-bs-target="#modalErroFinalizarEncomenda" hidden>modalerro</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )} 
        </>
    )
}

export default Finalizar