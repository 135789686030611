import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { CartContext } from "../contexts/cartContext";
import { Link } from "react-router-dom";
import { getParameterByAliasAPI } from "../data/parameter";
import slugify from "react-slugify";
import { AuthContext } from "../contexts/authContext";
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const CartBar = () => {
    const { cartItems, deliveryMode, setItemDelete } = useContext(CartContext);
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [loading, setLoading] = useState(false)
    const [precoTotal, setPrecoTotal] = useState(0)
    const [qttTotal, setQttTotal] = useState(0)
    const [casasDecimais, setCasasDecimais] = useState(2)
    const { userData } = useContext(AuthContext)

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    useEffect(() => {
        // const total = cartItems.reduce((acc, artigo) => acc + (artigo.preco * artigo.qtt), 0);
        let total = 0
        cartItems.filter((artigo => {
            if(artigo.ecovalor == 1 && userData.ecoisento == false){
                return total += (artigo.preco * artigo.qtt) + (artigo.eecoval * artigo.qtt)
            }else{
                return total += artigo.preco * artigo.qtt
            }
        }))
        const totalQtt = cartItems.reduce((qtt, artigo) => Number(qtt) + (Number(artigo.qtt)), 0);
        setPrecoTotal(parseFloat(total+(deliveryMode ? (deliveryMode.preco*totalQtt) : 0)));
        setQttTotal(totalQtt)
    }, [cartItems, deliveryMode])

    useEffect(() => {
        const getDataInitial = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getCasasDecimais()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getDataInitial()
    }, [])

    return(
        <>
            <li className="nav-item dropdown docarrinho">
                <a href="javascript:void(null);" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                    <i className="bi bi-cart3 p-2"></i><span className="top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {qttTotal || 0}
                    </span><span className="d-sm-block d-md-none">{t(currentLanguage+'_carrinho')}</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-cart" role="menu">
                    {cartItems && cartItems.length > 0 ? (
                        <>
                            <li>
                                <Link className="dropdown-item text-center" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/carrinho`}>
                                    {t(currentLanguage+'_ver-carrinho')}
                                </Link>
                            </li>
                            <li>
                                <table id="cartdropdown" className="table table-hover ">
                                    <thead>
                                        <tr>
                                            <th style={{width:"50%"}}>{t(currentLanguage+'_artigo')}</th>
                                            <th style={{width:"10%"}}>{t(currentLanguage+'_qtt')}</th>
                                            <th style={{width:"20%"}} className="text-center">{t(currentLanguage+'_subtotal')}</th>
                                            <th style={{width:"20%"}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems && cartItems.length > 0 && cartItems.map(function(artigo, index) {
                                            return(
                                                <tr key={"itemCarrinhoNav"+index}>
                                                    <td data-th="Produto">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                            <h5 className="nomargin bs-truncate" style={{width:"180px",fontWeight:"inherit"}} data-bs-toggle="tooltip" data-bs-placement="left" title={artigo.nome}>{artigo.nome}</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-th="Quantidade" className="align-center">
                                                        <p>{artigo.qtt}</p>
                                                    </td>
                                                    <td data-th="Subtotal" className="text-center">
                                                        {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.preco*artigo.qtt),)}
                                                    </td>
                                                    <td>
                                                        <button type="button" data-bs-toggle="modal" data-bs-target="#modalDeleteCartItem" onClick={() => setItemDelete({nome : artigo.nome, referencia: artigo.referencia})} className="btn btn-secondary btn-sm mx-2 btn-danger pull-right"><i className="bi bi-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {deliveryMode &&(
                                            <tr key={"modoEntregaLinha"}>
                                                <td data-th="Produto">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <h5 className="nomargin bs-truncate" style={{width:"180px",fontWeight:"inherit"}} data-bs-toggle="tooltip" data-bs-placement="left" title={deliveryMode.design}>
                                                                {t(currentLanguage+"_"+slugify(deliveryMode.nome))}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-th="Quantidade" className="align-center">
                                                    {qttTotal}
                                                </td>
                                                <td data-th="Subtotal" className="text-center">
                                                    {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco * qttTotal))}
                                                </td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr className="visible-xs d-none">
                                            <td className="align-center">
                                                <strong>
                                                    {t(currentLanguage+'_total')} {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(precoTotal)}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:"48%"}}></td>
                                            <td style={{width:"8%"}}></td>
                                            <td className="precoTotal">
                                                <strong>
                                                    {t(currentLanguage+'_total')} {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(precoTotal)}
                                                </strong>
                                            </td>
                                            <td style={{width:"22%"}}></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </li>
                            <li className="divider"></li>
                            {/* <li>
                                <Link className="dropdown-item text-center" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/carrinho`}>
                                    {t(currentLanguage+'_ver-carrinho')}
                                </Link>
                            </li> */}
                            <li>
                                <Link className="dropdown-item text-center keepbuy" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/loja`}>
                                    {t(currentLanguage+'_continuar-comprar')}
                                </Link>
                            </li>
                        </>
                    ):(
                        <div className="w-100 p-2 text-white text-center">
                            <p>{t(currentLanguage+'_carrinho-vazio')}</p>
                            <li>
                                <Link className="dropdown-item text-center keepbuy" to={VITE_PUBLIC_ROOT+`/${currentLanguage}/loja`}>
                                    {t(currentLanguage+'_continuar-comprar')}
                                </Link>
                            </li>
                        </div>
                    )}
                </ul>
            </li>
        </>
    )
}

export default CartBar