import { useEffect, useState, useContext, useRef } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from "../../components/MetaPages";
import LoadingPage from "../../components/LoadingPage";
import { getPageAPI } from "../../data/tpagina";
import { getParameterByAliasAPI } from "../../data/parameter";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { CartContext } from "../../contexts/cartContext";
import { updateCartQttPHC } from "../../data/apiPHC";
import ModalFinalizarCompra from "../../components/ModalFinalizarCompra";
import slugify from "react-slugify";
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import './carrinho.scss'

import ReactGA from 'react-ga4';

const Carrinho = () => {
    const { cartItems, deliveryMode, updateCart, setCartItems, setItemDelete  } = useContext(CartContext);
    const { userData } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [page, setPage] = useState("")
    const [nomeCliente, setNomeCliente] = useState("")
    const [delay, setDelay] = useState(0)
    const timerRef = useRef(null);
    const [initialized, setInitialized] = useState(false)
    const [precoTotal, setPrecoTotal] = useState(0)
    const [casasDecimais, setCasasDecimais] = useState(2)
    const [qttTotal, setQttTotal] = useState(0)

    const [inTimeout, setInTimeout] = useState(false)

    useEffect(() => {
        let total = 0
        cartItems.filter((artigo => {
            if(artigo.ecovalor == 1 && userData.ecoisento == false){
                return total += (artigo.preco * artigo.qtt) + (artigo.eecoval * artigo.qtt)
            }else{
                return total += artigo.preco * artigo.qtt
            }
        }))
        // const total = cartItems.reduce((acc, artigo) => acc + (artigo.preco * artigo.qtt && (artigo.ecovalor == 1) + ) , 0);
        const totalQtt = cartItems.reduce((qtt, artigo) => Number(qtt) + (Number(artigo.qtt)), 0);
        setPrecoTotal(parseFloat(total+(deliveryMode ? (deliveryMode.preco * totalQtt) : 0)).toFixed(3));
        setQttTotal(totalQtt)
    }, [cartItems, deliveryMode])

    const updateCartQuantitys = async () => {
        const data = { no : userData.no, products: cartItems}
        try {
            await updateCartQttPHC(data,t, currentLanguage)
            updateCart()
        } catch (error) {
            updateCart()
        }
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    useEffect(() => {
        if (delay === 0 && initialized === true) {
            updateCartQuantitys()
        }else{
            setInitialized(true)
        }
    }, [delay]);

    const getNomeCliente = async () => {
        const response = await getParameterByAliasAPI("nomecliente")
        if(response.varchar){
            setNomeCliente(response.varchar)
        }
    }

    const getPage = async () => {
        const response = await getPageAPI("carrinho","carrinho",currentLanguage)
        setPage(response)
    }

    useEffect(() => {
        const getData = async () => {
            if (!loading) {
                setLoading(true);
                try {
                    await Promise.all([
                        getNomeCliente(),
                        getPage(),
                        getCasasDecimais()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        getData();
        console.log('eco ' + userData.ecoisento + ', tabiva ' + userData.tabiva)
    }, []);

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    // console.log(cartItems)
    
    if(loading){
        return (
            <div style={{padding:"50px 0", width:"100%", display:"flex", flexDirection:"column"}}>
                <LoadingPage/>
            </div>
        )    
    }else{
        return (
            <>
                <ModalFinalizarCompra/>
                {page && nomeCliente &&(
                    <MetaPages newLang={language} pageTitle={nomeCliente +" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
                )}
                <section>
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12">
                                <h2 className="mbr-section-title mbr-fonts-style align-center mb-2 display-2">
                                    <>
                                        {cartItems.length > 0 &&(
                                            <strong>{t(currentLanguage+'_o-seu-carrinho')}</strong>
                                        )}
                                    </>
                                    <>
                                        {!cartItems.length > 0 &&(
                                            <strong>{t(currentLanguage+'_carrinho-vazio')}</strong>
                                        )}
                                    </>
                                </h2>
                            </div>
                        </div>
                        {!cartItems.length > 0 &&(
                            <div className="w-100 align-center">
                                <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/loja`} className="btn btn-secondary">
                                    {t(currentLanguage+'_continuar-comprar')}
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
                {cartItems.length > 0 &&(
                    <section className="pt-5 pb-5 pagina-carrinho">
                        <div className="container">
                            <table id="cart" className="table table-hover table-condensed">
                                <thead>
                                <tr>
                                    <th style={{width:"45%"}}>
                                        {t(currentLanguage+'_produto')}
                                    </th>
                                    <th style={{width:"10%"}}>
                                        {t(currentLanguage+'_preco')}
                                    </th>
                                    <th style={{width:"5%"}}>
                                        {t(currentLanguage+'_quantidade')}
                                    </th>
                                    <th style={{width:"12%"}} className="text-center">
                                        {t(currentLanguage+'_ecovalor')}
                                    </th>
                                    <th style={{width:"16%"}} className="text-center">
                                        {t(currentLanguage+'_subtotal')}
                                    </th>
                                    <th style={{width:"12%"}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {cartItems.map(function(artigo, index) {
                                    return(
                                        <tr key={index}>
                                            <td data-th="Produto">
                                            <div className="row">
                                                {/* <div className="col-sm-2 hidden-xs d-none">
                                                    <img src="http://placehold.it/100x100" alt="..." className="img-responsive" />
                                                </div> */}
                                                <div className="col-sm-10">
                                                    <h5 className="nomargin">
                                                        {artigo.nome}
                                                    </h5>
                                                    {/* <p>{artigo.marca} - {artigo.ic} - {artigo.iv}</p> */}
                                                </div>
                                            </div>
                                            </td>
                                            <td data-th="Preço">
                                                {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.preco),)} 
                                            </td>
                                            <td data-th="Quantidade">
                                                <input type="number" className="form-control text-center" min={1} max={artigo.stock} value={artigo.qtt} 
                                                    onChange={(e) => {
                                                        let newQtt = parseInt(e.target.value);
                                                        // if(newQtt > artigo.stock){
                                                        //     newQtt = artigo.stock
                                                        // }
                                                        // if (newQtt <= artigo.stock) {
                                                            setCartItems(prevState => prevState.map((item, i) =>
                                                                i === index ? { ...item, qtt: newQtt } : item
                                                            ));
                                                            if (timerRef.current) {
                                                                clearTimeout(timerRef.current);
                                                            }
                                                            setInTimeout(true)
                                                            timerRef.current = setTimeout(() => {
                                                                setDelay(0);
                                                                setInTimeout(false)
                                                            }, 3000);
                                                            setDelay(3000);
                                                        // }
                                                    }} 
                                                />
                                            </td>
                                            <td data-th="Ecovalor" className="text-center">
                                                {artigo.ecovalor == 1 && userData.ecoisento == false ? (
                                                    new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.qtt * Number(artigo.eecoval)))
                                                ):(
                                                    0
                                                )}
                                                
                                            </td>
                                            <td data-th="Subtotal" className="text-center">
                                                {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.qtt * artigo.preco))} 
                                            </td>
                                            <td className="actions text-end" data-th="">
                                                <button type="button" className="btn btn-secondary btn-sm mx-2 btn-danger" data-bs-toggle="modal" data-bs-target="#modalDeleteCartItem" onClick={() => setItemDelete({nome : artigo.nome, referencia: artigo.referencia})}>
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {deliveryMode &&(
                                    <tr key={"modoEntregaRow"}>
                                        <td data-th="Produto">
                                        <div className="row">
                                            <div className="col-sm-2 hidden-xs d-none">
                                                <img src="http://placehold.it/100x100" alt="..." className="img-responsive" />
                                            </div>
                                            <div className="col-sm-10">
                                                <h5 className="nomargin">{t(currentLanguage+"_"+slugify(deliveryMode.nome))}</h5>
                                                {/* <p>{artigo.marca} - {artigo.ic} - {artigo.iv}</p> */}
                                            </div>
                                        </div>
                                        </td>
                                        <td data-th="Preço">
                                            {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco))} 
                                        </td>
                                        <td data-th="Quantidade" className="text-center"> 
                                            {qttTotal}
                                        </td>
                                        <td data-th="Ecovalor" className="text-center">
                                                {/* {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(artigo.ecovalor * artigo.qtt),)}  */}
                                        </td>
                                        <td data-th="Subtotal" className="text-center">
                                            {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(deliveryMode.preco * qttTotal))} 
                                        </td>
                                        <td className="actions" data-th="">
                                            {/* <button className="btn btn-secondary btn-sm mx-2 btn-danger" onClick={() => deleteItemCart(artigo.referencia)}>
                                                <i className="bi bi-trash"></i>
                                            </button> */}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                                <tfoot>
                                <tr className="visible-xs d-none">
                                    <td className="text-center">
                                        <strong>
                                            {t(currentLanguage+'_total')} {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(precoTotal))}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/loja`} className="btn btn-warning">
                                            <i className="fa fa-angle-left"></i> {t(currentLanguage+'_continuar-comprar')}
                                        </Link>
                                    </td>
                                    <td colspan="3" className="hidden-xs text-end"></td>
                                    <td className="hidden-xs text-center">
                                        <strong>
                                            {t(currentLanguage+'_total')} {new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(precoTotal))}
                                        </strong>
                                    </td>
                                    <td colSpan="3" className="text-end">
                                        <button disabled={inTimeout} type="button" className="btn btn-success btn-block" data-bs-toggle="modal" data-bs-target="#modalFinalizarCompra">
                                            {t(currentLanguage+'_finalizar')}
                                            <i className="bi bi-caret-right"></i>
                                        </button>
                                        {/* <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/comprar`} className="btn btn-success btn-block">
                                            {t(currentLanguage+'_finalizar')} <i className="bi bi-caret-right"></i>
                                        </Link> */}
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </section>
                )}
            </>
        )
    }   
}

export default Carrinho