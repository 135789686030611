import React, { useState, useEffect} from "react";
import Slider from "react-slick";
import { getMultimediaByCategoryAndLanguage } from "../../data/multimedia";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './DashboardPromo.scss'

const DashboardPromo = () => {

    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
      const [images, setImages] = useState("")
      const {i18n: {language} } = useTranslation();
      const [currentLanguage] = useState(language);
    
      async function getImagesSlider(){
        if(!images){
          const response = await getMultimediaByCategoryAndLanguage("promocoes",currentLanguage)
          setImages(response)
        }
      }
    
      useEffect(() => {
        getImagesSlider()
      }, [])

      var settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        respondTo: 'slider', 
        adaptiveHeight: true
      };
      
  return (
    <>
    {images && images.length > 1 ? (
      <Slider {...settings}>
          {images.map(( image, imageIndex) => (
              <div className="insideclip" key={imageIndex}>
                {/* <Link to={image.url}> */}
                  <img src={BaseImgPath+image.path} className="img-fluid"  alt={image.description} style={{}}/>
                {/* </Link> */}
              </div>
          ))}
      </Slider>
    ): images && images.length === 1 ? (
      <>
      {images.map((image) => (
        <img src={BaseImgPath+image.path} className="img-fluid"  alt={image.description} style={{}}/>
      ))}
      </>
    ): images && images.length === 0 (<div></div>)
    }
    </>

    // <Slider {...settings}>
    //     {images && images.length > 0 && images.map(( image, imageIndex) => (
    //         <div className="insideclip" key={imageIndex}>
    //           {/* <Link to={image.url}> */}
    //             <img src={BaseImgPath+image.path} className="img-fluid"  alt={image.description} style={{}}/>
    //           {/* </Link> */}
    //         </div>
    //     ))}
    // </Slider>
  )
}

export default DashboardPromo