import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { getPageAPI } from "../../data/tpagina.jsx";
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import MetaPages from "../../components/MetaPages.jsx";
import './obrigado.scss'
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
//  teste de passagem de parametros atraves do route
//  Obrigado pela sua preferência<br/>{data}</p>
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia.jsx";

import ReactGA from 'react-ga4';

const Obrigado = () => {
    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
    const { pagina, nencomenda, total } = useParams()
    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [loading, setLoading] = useState(true)
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState("")
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")

    const [ibanCliente, setIbanCliente] = useState("")

    const [ image, setImage] = useState("")

    const [contentEncomenda, setContentEncomenda] = useState("")

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("obrigado", currentLanguage)
        setImage(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getIbanCliente(){
        const response = await getParameterByAliasAPI("clienteiban")
        setIbanCliente(response.varchar)
    }

    useEffect(() => {
        getIbanCliente()
    }, [nencomenda])

    async function getPage(){
        if(!page){
            setLoading(true)
            const response = await getPageAPI(pagina, "obrigado", currentLanguage)
            setPage(response)
            setLoading(false)
        }
    }

    useEffect(() => {
        getNomeCliente()
        getPage()
        getImage()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    useEffect(() => {
        // vai ser necessario validar a formadepagamento para alterar os conteudos da página de acordo com cada tipo dinamicamente
        
        if(ibanCliente && page && nencomenda && total){
            let contentEncomenda = Base64.decode(page.pageContent)
            contentEncomenda = contentEncomenda.replace(/%IBAN%/g, ibanCliente);
            contentEncomenda = contentEncomenda.replace(/%NENCOMENDA%/g, nencomenda);
            let totalDecoded = Base64.decode(total)
            contentEncomenda = contentEncomenda.replace(/%TOTAL%/g, totalDecoded);
            setContentEncomenda(contentEncomenda)
        }
    }, [ibanCliente, page])
    return (
        <>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="pagecontent">
                <div className="container">
                    <div className="row align-items-center">
                        
                        <div className="col-12 col-lg">
                            <div className="text-wrapper">
                                {/* {page &&(
                                    <h5 className="text-grey"><strong>{page.pageTitle}</strong></h5>    
                                )} */}
                                {page && !nencomenda &&(
                                    <>
                                        <h3 className="mbr-section-title mbr-fonts-style mb-3 display-5" style={{fontWeight: 400}}><strong>{page.pageTitle}</strong></h3>
                                        <p className="mbr-text mbr-fonts-style display-7" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></p>
                                    </>
                                )}
                                {page && nencomenda &&(
                                    <>
                                        <h3 className="mbr-section-title mbr-fonts-style mb-3 display-5" style={{fontWeight: 400}}><strong>{page.pageTitle}</strong></h3>
                                        <p className="mbr-text mbr-fonts-style display-7" dangerouslySetInnerHTML={{__html: contentEncomenda}}></p>
                                    </>
                                )}
                            </div>
                        </div>
                        {image &&(
                            <div className="col-12 col-lg-5 comimagem" style={{backgroundImage : 'url('+BaseImgPath+image.path+')'}}>
                                <div className="image-wrapper">
                                    <img src="./../assets/images/red_background.jpg" alt="zoomtyre" className="img-responsive" style={{width: "100%"}}/>   
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Obrigado