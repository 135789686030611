import { Base64 } from "js-base64";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { setSessionToken, setLocalToken} from "../services/dataStorage.service";
import { Notify } from "../services/Notify.service";
const apiUrl = import.meta.env.VITE_API_URL

export async function getInfoPHC(){
    try {
        const response = await axios.get(apiUrl+"infoapiphc")   
        console.log(Base64.decode(response.data.RunCodeResult) )
    } catch (error) {
        console.log(error)
    }
}

export async function loginPHC(dataToEncode){
    try {
        const data = Base64.encode(JSON.stringify(dataToEncode))
        const response = await axios.post(apiUrl+"loginapiphc/", {
            data : data
        })
        if(response.data.token){
            const decoded = jwtDecode(response.data.token);
            if(decoded.remember){
                setLocalToken(response.data.token)
            }else{
                setSessionToken(response.data.token)
            }
        }
    } catch (error) {
        throw error
    }
}

export async function passwordRecoverPHC(dataToEncode, token){
    try {
        const data = Base64.encode(JSON.stringify(dataToEncode))
        const response = await axios.post(apiUrl+"recoverpasswordapiphc/", {
            data : data,
            tokencaptcha : token
        })
        console.log(response.data.message)
    } catch (error) {
        throw error
    }
}

export async function getProductsPHC(no){
    try {
        const response = await axios.get(apiUrl+'productsapiphc/'+no)
        if(response.data.products){
            return response.data.products
        }
        return response.data.products
    } catch (error) {
        throw error
    }
}

export async function getUserDataPHC(no){
    try {
        const response = await axios.get(apiUrl+'getuserdata/'+no)
        if(response.data.userData){
            return response.data.userData
        }
    } catch (error) {
        throw error
    }
}

export async function getCartDataPHC(no, t,currentLanguage){
    try {
        const response = await axios.get(apiUrl+'cartget/'+no)
        // const response = await axios.get('http://localhost:8080/cartget/'+no)
        if(response.data.message === "products-removed"){
            Notify("info", t(currentLanguage+"_produto-carrinho-indisponivel"))
        }
        return response.data
    } catch (error) {
        throw error
    }
}

export async function updateCartQttPHC(dataToEncode,t,currentLanguage){
    try {
        const data = Base64.encode(JSON.stringify(dataToEncode))
        const response = await axios.put(apiUrl+'update/cart/quantity/',{
            data: data
        });
        Notify("success", t(currentLanguage+"_cart-update-success"))
        // const response = await axios.get(apiUrl+'cartget/'+no)
        // console.log(response.data)
    } catch (error) {
        if(error.response.data.status === 400){
            let txt = t(currentLanguage+"_cart-qttproduto-excede-stock")
            txt = txt.replace(/%NOMEPRODUTO%/g, error.response.data.product);
            Notify("warn", txt)
        }
        throw error
    }
}

export async function getAlternativasDataPHC(ref){
    try {
        if(!ref){
            throw error
        }
        const response = await axios.get(apiUrl+'product/alternativas/apiphc/'+ref)
        return response.data.products
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function updatePasswordPHC(dataToEncode){
    try {
        const data = Base64.encode(JSON.stringify(dataToEncode))
        const response = await axios.put(apiUrl+'user/update/password',{
            data: data
        });
        console.log(response.data.message)
    } catch (error) {
        console.log(error.response.data.message)
        throw error
    }
}

export async function getAllEncomendasPHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/encomendas/'+no)
        return response.data.encomendas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getEncomendasAbertasPHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/encomendas/abertas/'+no)
        return response.data.encomendas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getProdutosEncomendasPHC(bostamp){
    try {
        if(!bostamp){
            throw error
        }
        const response = await axios.get(apiUrl+'get/encomenda/produtos/'+bostamp)
        return response.data.produtosEncomendas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAllFaturasPHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/faturas/'+no)
        return response.data.faturas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getLinhasFaturasPHC(stamp){
    try {
        if(!stamp){
            throw error
        }
        const response = await axios.get(apiUrl+'get/faturas/linhas/'+stamp)
        return response.data.linhasFaturas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getMoradasPHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/moradas/'+no)
        return response.data.moradas
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getModosEntregaPHC(zona){
    try {
        const response = await axios.get(apiUrl+'get/modos/entrega/'+zona)
        return response.data.modosEntrega
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function changeModoEntregaPHC(data){
    try {
        data = JSON.stringify(data)
        data = Base64.encode(data)
        const response = await axios.put(apiUrl+'update/cart/modo/entrega/', {
            data: data
        })
        return response.data.message
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getPaisesPHC(){
    try {
        const response = await axios.get(apiUrl+'get/paises')
        return response.data.paises
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getContaCorrentePHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/conta/corrente/'+no)
        return response.data.contacorrente
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getTotaisContaCorrentePHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/conta/corrente/totais/'+no)
        return response.data.totais
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getContaCorrentePendentePHC(no){
    try {
        if(!no){
            throw error
        }
        const response = await axios.get(apiUrl+'get/conta/corrente/pendentes/'+no)
        return response.data.contacorrente
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function sendEmailFaturaPHC(nomeCL, emailCL, noCL, nFatura, dataFatura, totalFatura, alias, language){
    try {
        if(!nomeCL || !emailCL || !noCL || !nFatura || !dataFatura || !totalFatura || !alias || !language){
            throw new Error("Campos em falta")
        }
        const response = await axios.post(apiUrl+'pedido/fatura', {
            nome: nomeCL,
            emailCliente: emailCL,
            no: noCL,
            nFatura: nFatura, 
            dataFatura: dataFatura,
            totalFatura: totalFatura,
            alias: alias,
            language: language
        })
        return response.data.message
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function finalizarEncomendaPHC(language, nomeCliente, no,emailCliente, observacao, metodoPagamento, modoEntrega, morada, localidade, cPostal, cartFront,moradaDescarga, pais){
    try {
        const response = await axios.post(apiUrl+'encomenda/finalizar', {
            no: no,
            emailCliente: emailCliente,
            observacao: observacao,
            metodoPagamento: metodoPagamento,
            modoEntrega: modoEntrega,
            morada: morada,
            localidade: localidade,
            cPostal: cPostal,
            cartFront: cartFront,
            moradaDescarga: moradaDescarga,
            language: language,
            nomeCliente: nomeCliente,
            pais: pais
        })
        return response
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function finalizarEncInterPHC(language, nomeCliente, no,emailCliente, observacao, metodoPagamento, modoEntrega, morada, localidade, cPostal, cartFront,moradaDescarga, pais, tabiva, ecoisento){
    try {
        const response = await axios.post(apiUrl+'encomenda/finalizarint', {
            no: no,
            emailCliente: emailCliente,
            observacao: observacao,
            metodoPagamento: metodoPagamento,
            modoEntrega: modoEntrega,
            morada: morada,
            localidade: localidade,
            cPostal: cPostal,
            cartFront: cartFront,
            moradaDescarga: moradaDescarga,
            language: language,
            nomeCliente: nomeCliente,
            pais: pais,
            clTabiva: tabiva,
            clEcoisento: ecoisento
        })
        return response
    } catch (error) {
        console.log(error)
        throw error
    }
}
